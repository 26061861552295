
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
  scroll-behavior: smooth;
}
body::-webkit-scrollbar {
  width: 11px;
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
body {
  background: #000;
  width: 100%;
  height: 100%;
}
