@tailwind base;
@tailwind components;
@tailwind utilities;

.body{
  font-display: optional !important;
}

.Collapsible .is-open {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.82);
  height: 300px;
  z-index: 10;
}
.Collapsible__contentInner {
  padding: 30px 0px;
}
.react-multiple-carousel__arrow--left {
  position: absolute;
  left: calc(2% + 1px);
  border: 2px solid gray !important;
}
.react-multiple-carousel__arrow--right {
  position: absolute;
  right: calc(2% + 1px);
  border: 2px solid gray !important;
}
.my-collapse {
  height: auto;
  transition: height 0.2s ease-in-out;
}
video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
video::-webkit-media-controls-overlay-play-button {
  display: none;
}
.rec-arrow-left,
.rec-arrow-right {
  background-color: transparent !important;
  border: 2px solid rgba(255, 255, 255, 0.6) !important;
  border-radius: 50% !important;
  font-size: 16px;
  align-items: center !important;
  width: 30px !important;
  min-width: 40px !important;
  height: 40px !important;
  line-height: 0px !important;
}
.rec-arrow-left:hover,
.rec-arrow-right:hover {
  background: transparent !important;
}
.video-react-big-play-button {
  /* display: none !important; */
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translateX(-50%) translateY(-50%) !important;
  background: url("./assets/play.png") no-repeat !important;
  background-size: contain !important;
  height: 70px !important;
  border: none !important;
  line-height: 0px !important;
}
.video-react-big-play-button:before {
  display: none !important;
}
.video-react-poster {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  background-size: cover !important;
}
.CookieDeclaration {
  display: none !important;
}
/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background: rgba(255, 255, 255, 0.82);
}

.swiper {
  width: 100%;
  height: 100%;
  margin-top: 50px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination-bullet {
  visibility: hidden;
}
.swiper-button-prev::after {
  content: url(" ./assets/arrow-back.png ") !important;
  transform: scale(1.3);
}
.swiper-button-next::after {
  content: url(" ./assets/arrow-right.png ") !important;
  transform: scale(1.3);
}
.swiper-button-disabled{
  opacity: 1 !important;
}